import React from 'react'
import { MDBCol, MDBRow, MDBContainer } from 'mdbreact'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faFax, faEnvelope, faInfoCircle, } from '@fortawesome/pro-solid-svg-icons'


class CardIconsOffices extends React.Component {
  render() {
    return (
      <section className="bg-white">
        <MDBContainer>
          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">          
            Other support regions
            <span id="regional-support" className="anchor"></span>
          </h2>
          <MDBRow className="mt-5">
            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div className="card-body d-flex flex-row align-items-center" style={{ minHeight: '150px', maxHeight: '150px' }} >
                  <div style={{ minWidth: '85px', maxWidth: '85px', }} >
                    <StaticImage
                      src={'../../static/assets/france.png'}
                      className="mr-1"
                      alt="alt image"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-3"> France </h3>
                  </div>
                </div>
                <div className="card-body-prods">
                  <ul className="fa-ul text-medium">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon icon={faInfoCircle} className="ml-2 mr-5 va-m" />
                        </span>
                          <a href="http://www.usr-emea.com/support/s-main-menu.asp?loc=frnc" className="effect"> Visit support pages </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>

            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div className="card-body d-flex flex-row align-items-center" style={{ minHeight: '150px', maxHeight: '150px' }} >
                  <div style={{ minWidth: '85px', maxWidth: '85px', }} >
                    <StaticImage
                      src={'../../static/assets/germany.png'}
                      className="mr-1"
                      alt="alt image"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-3"> Germany </h3>
                  </div>
                </div>
                <div className="card-body-prods">
                  <ul className="fa-ul text-medium">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon icon={faInfoCircle} className="ml-2 mr-5 va-m" />
                        </span>
                          <a href="http://www.usr-emea.com/support/s-main-menu.asp?loc=frnc" className="effect"> Visit support pages </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>

            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div className="card-body d-flex flex-row align-items-center" style={{ minHeight: '150px', maxHeight: '150px' }} >
                  <div style={{ minWidth: '85px', maxWidth: '85px', }} >
                    <StaticImage
                      src={'../../static/assets/italy.png'}
                      className="mr-1"
                      alt="alt image"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-3"> Italy </h3>
                  </div>
                </div>
                <div className="card-body-prods">
                  <ul className="fa-ul text-medium">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon icon={faInfoCircle} className="ml-2 mr-5 va-m" />
                        </span>
                          <a href="http://www.usr-emea.com/support/s-main-menu.asp?loc=itly" className="effect"> Visit support pages </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>

            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div className="card-body d-flex flex-row align-items-center" style={{ minHeight: '150px', maxHeight: '150px' }} >
                  <div style={{ minWidth: '85px', maxWidth: '85px', }} >
                    <StaticImage
                      src={'../../static/assets/spain.png'}
                      className="mr-1"
                      alt="alt image"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-3"> Spain </h3>
                  </div>
                </div>
                <div className="card-body-prods">
                  <ul className="fa-ul text-medium">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon icon={faInfoCircle} className="ml-2 mr-5 va-m" />
                        </span>
                          <a href="http://www.usr-emea.com/support/s-main-menu.asp?loc=span" className="effect"> Visit support pages </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>

            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div className="card-body d-flex flex-row align-items-center" style={{ minHeight: '150px', maxHeight: '150px' }} >
                  <div style={{ minWidth: '85px', maxWidth: '85px', }} >
                    <StaticImage
                      src={'../../static/assets/united-kingdom.png'}
                      className="mr-1"
                      alt="alt image"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-3"> United Kingdom</h3>
                  </div>
                </div>
                <div className="card-body-prods">
                  <ul className="fa-ul text-medium">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon icon={faInfoCircle} className="ml-2 mr-5 va-m" />
                        </span>
                          <a href="http://www.usr-emea.com/support/s-main-menu.asp?loc=span" className="effect"> Visit support pages </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>

            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div className="card-body d-flex flex-row align-items-center" style={{ minHeight: '150px', maxHeight: '150px' }} >
                  <div style={{ minWidth: '85px', maxWidth: '85px', }} >
                    <StaticImage
                      src={'../../static/assets/europe.png'}
                      className="mr-1"
                      alt="alt image"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-3"> Other European </h3>
                  </div>
                </div>
                <div className="card-body-prods">
                  <ul className="fa-ul text-medium">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon icon={faInfoCircle} className="ml-2 mr-5 va-m" />
                        </span>
                          <a href="http://www.usr-emea.com/support/s-main-menu.asp?loc=emea" className="effect"> Visit support pages </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>            

            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div className="card-body d-flex flex-row align-items-center" style={{ minHeight: '150px', maxHeight: '150px' }} >
                  <div style={{ minWidth: '85px', maxWidth: '85px', }} >
                    <StaticImage
                      src={'../../static/assets/all-other-regions.png'}
                      className="mr-1"
                      alt="alt image"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-3"> Other regions </h3>
                  </div>
                </div>
                <div className="card-body-prods">
                  <ul className="fa-ul text-medium">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon icon={faInfoCircle} className="ml-2 mr-5 va-m" />
                        </span>
                          <a href="https://support.usr.com/support/" className="effect"> Visit support pages </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    )
  }
}

export default CardIconsOffices
